import { useModule } from '@/controllers/manager'
import { messageAlerts } from '@/middlewares/responseHandler'
import store from '@/store/index'

export default {
  namespaced: true,
  state: {
    module: 'albaranes',
    loading: false,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async fetchAlbaranes({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.getAll(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchAlbaran({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.getById(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addAlbaran({ commit, state }, albaranData) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.create(albaranData)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return res.data
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async deleteAlbaran({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.deleteById(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async printAlbaran({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        let res = await controller.printAlbaran(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return res
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}

export const albaranes = {
    estados: [
        {
            label: "EN ESPERA",
            value: "en_espera",
            variant: "secondary"
        },
        {
            label: "PENDIENTE",
            value: "pendiente",
            variant: "warning"
        },
        {
            label: "ENTREGADO",
            value: "entregado",
            variant: "success"
        },
    ]
}
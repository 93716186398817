<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refAlbaranesListTable"
        class="position-relative"
        :items="fetchAlbaranes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchAlbaranes"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              class="align-middle"
              small
            />
            <strong class="ml-1">Cargando datos...</strong>
          </div>
        </template>

        <!-- Column: Codigo -->
        <template #cell(codigo)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="editAlbaran(data.item.id)"
          >
            #{{ data.item.codigo }}
          </b-link>
        </template>

        <!-- Column: Fecha -->
        <template #cell(fecha)="data">
          <span>{{ formatDate(data.item.fecha) }}</span>
        </template>

        <!-- Column: Nº pedido -->
        <template #cell(pedido_codigo)="data">
          <router-link
            v-if="data.item.id"
            target="_blank"
            style="padding: 6px 0 0 6px"
            :to="{ name: 'pedido-edit', params: { id: data.item.fk_pedido } }"
          >
            <slot>{{ data.item.pedido_codigo }}
              <feather-icon
                icon="ExternalLinkIcon"
                size="16"
                class="align-top ml-0"
              />
            </slot>
          </router-link>
        </template>

        <!-- Column: Nota -->
        <template #cell(nota)="data">
          <div class="w-100 text-truncate">
            {{ data.item.nota }}
          </div>
        </template>

        <!-- Column: Estado -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.estado) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row>
            <!-- Editar -->
            <b-button
              :id="`edit-${data.item.id}`"
              variant="success"
              class="btn-icon mr-1 mb-1"
              @click="editAlbaran(data.item.id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-tooltip
              :target="`edit-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Ver</b>
            </b-tooltip>

            <!-- Imprimir -->
            <b-button
              :id="`imprimir-${data.item.id}`"
              variant="info"
              class="btn-icon mr-1 mb-1"
              @click="imprimirAlbaran(data.item.id)"
            >
              <feather-icon icon="PrinterIcon" />
            </b-button>
            <b-tooltip
              :target="`imprimir-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Imprimir</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de
              {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAlbaranes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <ConfirmationModal
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      message="Esta acción no se puede revertir y se perderá el albaran."
      title="¿Está seguro de que desea eliminar el albaran?"
      @action="deleteAlbaran(selectedItem.id)"
      @close="deleteModal = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import useAlbaranesList from './useAlbaranesList'
import albaranesStoreModule from './albaranesStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    ConfirmationModal,
  },
  data() {
    return {
      deleteModal: false,
      selectedItem: {},
    }
  },
  methods: {
    editAlbaran(id) {
      this.$router.push({
        name: 'albaran-edit',
        params: {
          id,
        },
      })
    },
    deleteAlbaran(id) {
      this.$store.dispatch('app-albaranes/deleteAlbaran', id).then(() => {
        this.deleteModal = false
        this.refetchData()
      })
    },
    imprimirAlbaran(id) {
      this.$store
        .dispatch('app-albaranes/printAlbaran', id)
        .then(response => {
          window.open(response.route, '_blank')
        })
        .catch(error => {
          console.error('Error printing el albarán:', error)
        })
    },
  },
  setup() {
    const PEDIDOS_APP_STORE_MODULE_NAME = 'app-albaranes'

    // Register module
    if (!store.hasModule(PEDIDOS_APP_STORE_MODULE_NAME)) {
      store.registerModule(PEDIDOS_APP_STORE_MODULE_NAME, albaranesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PEDIDOS_APP_STORE_MODULE_NAME)) store.unregisterModule(PEDIDOS_APP_STORE_MODULE_NAME)
    })

    const {
      fetchAlbaranes,
      tableColumns,
      perPage,
      currentPage,
      totalAlbaranes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAlbaranesListTable,
      refetchData,

      // UI
      resolveStatusVariant,
      resolveStatusText,
      parseDate,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useAlbaranesList()

    return {
      fetchAlbaranes,
      tableColumns,
      perPage,
      currentPage,
      totalAlbaranes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAlbaranesListTable,
      refetchData,

      // UI
      resolveStatusVariant,
      resolveStatusText,
      parseDate,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
